import { useEffect, useState, useCallback } from 'react';
import { SavingsForm } from './SavingsForm';
import { SavingsChart } from './SavingsChart';
import { IFinancialCalculatorInputModuleFields } from '@/types/contentful';
import { trackWithFingerprint } from '~/utils/trackWithFingerprint';
import RichText from '../RichText';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Block, Inline } from '@contentful/rich-text-types';
import { usePublicRuntimeConfig } from '~/hooks/usePublicRuntimeConfig';

const RICH_TEXT_OVERRIDE: RenderNode = {
  [BLOCKS.PARAGRAPH]: (_node: Block | Inline, children) => {
    return <p className="text-sm text-black">{children}</p>;
  }
};

const useDebouncedEffect = (effect: () => void, deps: any[], delay: number) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);
    return () => clearTimeout(handler);
  }, [...deps, delay]);
};

interface CompoundInterestComponents {
  principal: number;
  annualRate: number;
  t: number;
  additionalContribution: number;
  contributionPeriods: number;
}

export const SavingsCalculator = (
  fields: IFinancialCalculatorInputModuleFields
) => {
  const { moduleHeader, moduleSubheader } = fields;
  const [currentSavings, setCurrentSavings] = useState('100');
  const [rateOfReturn, setRateOfReturn] = useState<number | 'custom'>(0.05);
  const [customRate, setCustomRate] = useState<string>('');
  const [extraContribution, setExtraContribution] = useState('10');
  const [contributionFrequency, setContributionFrequency] = useState(12);
  const [estimatedSavings, setEstimatedSavings] = useState(0);
  const [chartData, setChartData] = useState([] as any);
  const [savingsAgeRange, setSavingsAgeRange] = useState<[number, number]>([
    8, 16
  ]);

  const { webregUrl } = usePublicRuntimeConfig();

  const trackFormChanges = useCallback(() => {
    trackWithFingerprint('Form Manipulated', {
      form_type: 'Savings Calculator',
      current_savings: currentSavings,
      rate_of_return: rateOfReturn === 'custom' ? customRate : rateOfReturn,
      extra_contribution: extraContribution,
      contribution_frequency: contributionFrequency,
      savings_age_range: savingsAgeRange
    });
  }, [
    currentSavings,
    rateOfReturn,
    customRate,
    extraContribution,
    contributionFrequency,
    savingsAgeRange
  ]);

  useDebouncedEffect(
    () => {
      if (
        currentSavings !== '100' ||
        rateOfReturn !== 0.05 ||
        customRate !== '' ||
        extraContribution !== '10' ||
        contributionFrequency !== 12 ||
        savingsAgeRange[0] !== 1 ||
        savingsAgeRange[1] !== 18
      ) {
        trackFormChanges();
      }
    },
    [
      currentSavings,
      rateOfReturn,
      customRate,
      extraContribution,
      contributionFrequency,
      savingsAgeRange
    ],
    500
  );

  useEffect(() => {
    handleCalculate();
  }, [
    currentSavings,
    savingsAgeRange,
    rateOfReturn,
    extraContribution,
    contributionFrequency,
    customRate
  ]);

  const calculateTotal = (components: CompoundInterestComponents) => {
    const {
      principal,
      annualRate,
      t,
      additionalContribution,
      contributionPeriods
    } = components;
    let total = principal * Math.pow(1 + annualRate, t);
    let totalContributions = principal;
    for (let i = 1; i <= t * contributionPeriods; i++) {
      total +=
        additionalContribution *
        Math.pow(1 + annualRate, t - i / contributionPeriods);
      totalContributions += additionalContribution;
    }
    return { total, totalContributions };
  };

  const generateChartData = (components: CompoundInterestComponents) => {
    const data = [];
    const startingAge = savingsAgeRange[0];
    for (let year = 0; year <= components.t; year++) {
      const { total, totalContributions } = calculateTotal({
        ...components,
        t: year
      });
      data.push({
        age: startingAge + year,
        saved: totalContributions.toFixed(2),
        total: total.toFixed(2),
        earned: (total - totalContributions).toFixed(2)
      });
    }
    setChartData(data);
  };

  const handleCalculate = () => {
    const components: CompoundInterestComponents = {
      principal: parseFloat(currentSavings),
      annualRate:
        rateOfReturn === 'custom'
          ? (isNaN(parseFloat(customRate)) ? 0 : parseFloat(customRate)) / 100
          : rateOfReturn,
      t: savingsAgeRange[1] - savingsAgeRange[0],
      additionalContribution: parseFloat(extraContribution),
      contributionPeriods: contributionFrequency
    };
    if (
      isNaN(components.principal) ||
      isNaN(components.annualRate) ||
      isNaN(components.t) ||
      isNaN(components.additionalContribution)
    )
      return;
    const { total } = calculateTotal(components);
    setEstimatedSavings(parseFloat(total.toFixed(2)));
    generateChartData(components);
  };

  const stateHandlers = {
    setCurrentSavings,
    setRateOfReturn,
    setCustomRate,
    setExtraContribution,
    setContributionFrequency,
    setSavingsAgeRange
  };

  const formData = {
    currentSavings,
    rateOfReturn,
    customRate,
    extraContribution,
    contributionFrequency,
    savingsAgeRange,
    estimatedSavings
  };

  return (
    <div className="block-container">
      <div className="layout items-center !py-0 [&>div]:tablet:px-3 [&>div]:desktop:px-4">
        {(moduleHeader || moduleSubheader) && (
          <div className="mb-6 max-w-2xl tablet:mb-8">
            <h1 className="mb-4 text-center text-heading2-mobile font-medium leading-8 tablet:text-heading2 tablet:leading-12">
              {moduleHeader}
            </h1>
            <p className="text-center text-base leading-6 tablet:text-xl">
              {moduleSubheader}
            </p>
          </div>
        )}
        <div className="desktop:10/12 tablet:11/12 flex w-full flex-col gap-6 desktop:flex-row desktop:gap-8">
          <SavingsForm
            stateHandlers={stateHandlers}
            formData={formData}
            {...fields}
          />
          <SavingsChart
            chartData={chartData}
            estimatedSavings={estimatedSavings}
            savingsAgeRange={savingsAgeRange}
            {...fields}
          />
        </div>
        {fields.ctaText && (
          <button
            className={`mt-6 flex w-full min-w-32 items-center justify-center rounded-lg px-4 py-3 text-center text-base font-medium leading-4 tablet:hidden`}
            {...((fields.ctaColor || fields.ctaTextColor) && {
              style: {
                backgroundColor: fields.ctaColor,
                color: fields.ctaTextColor
              }
            })}
          >
            <a
              type="button"
              href={
                fields.registrationButton ? webregUrl : fields.ctaDestination
              }
            >
              {fields.ctaText}
            </a>
          </button>
        )}
        <div className="mr-auto mt-4">
          {fields.disclaimer && (
            <RichText
              data={fields.disclaimer}
              overrideOptions={RICH_TEXT_OVERRIDE}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SavingsCalculator;
